import React, { useContext, useRef } from "react";
import PicturesCard from "../../components/Eco/PicturesCard/PicturesCard";
import Hero from "../../components/Hero/Hero";
import "./style.css";
import { GlobalContext } from "../../components/Layout/Layout";
import { EcoTexts, NavTexts } from "../../texts/texts";
import CardLink from "../../components/Home/SellingPoint/CardLink";
import "aos/dist/aos.css";
import Aos from "aos";

const Eco = () => {
  Aos.init();

  const { language } = useContext(GlobalContext);

  const { current: PicturesCollection } = useRef([
    "/chedraui-textil-112.jpg",
    "/chedraui-textil-113.jpg",
    "/chedraui-textil-114.jpg",
    "/chedraui-textil-115.jpg",
    "/chedraui-textil-116.jpg",
    "/chedraui-textil-117.jpg",
    "/chedraui-textil-118.jpg",
    "/chedraui-textil-119.jpg",
    "/chedraui-textil-120.jpg",
    "/chedraui-textil-121.jpg",
    "/chedraui-textil-122.jpg",
  ]);

  const sellingPointsLinks = [
    {
      path: "/production",
      img: "/icons/chedraui-textil-icon-1.svg",
      text: NavTexts[1][language],
    },
    {
      path: "/fabric",
      img: "/icons/chedraui-textil-icon-2.svg",
      text: NavTexts[2][language],
    },
    {
      path: "/print",
      img: "/icons/chedraui-textil-icon-3.svg",
      text: NavTexts[3][language],
    },
    {
      path: "/clothing",
      img: "/icons/chedraui-textil-icon-4.svg",
      text: NavTexts[4][language],
    },
    {
      path: "/eco",
      img: "/icons/chedraui-textil-icon-5.svg",
      text: NavTexts[5][language],
    },
  ];

  return (
    <div className="screen eco">
      <Hero
        img="/chedraui-textil-111.jpg"
        title={EcoTexts[3][language]}
        subtitle={EcoTexts[0][language]}
      />
      <main>
        <div className="background-circle"></div>
        <div className="background-circle bottom"></div>
        <ul className="selling-points-list">
          {sellingPointsLinks.map((sellingPoint, index) => (
            <CardLink
              key={index}
              path={sellingPoint.path}
              img={sellingPoint.img}
              text={sellingPoint.text}
            />
          ))}
        </ul>
        <section className="eco-section">
          <h3 className="section-title" data-aos="fade-right">
            {EcoTexts[1][language][0]}{" "}
            <span className="outline-white">{EcoTexts[1][language][1]}</span>
          </h3>
          <p data-aos="fade-right">{EcoTexts[2][language]}</p>
          <div className="eco-blended">
            <p>{EcoTexts[4][language]}</p>
            <img src="blended.png" />
          </div>
          <PicturesCard collection={PicturesCollection} />
        </section>
        <div className="big-needle">
          <img src="icons/needle.svg" alt="" />
        </div>
      </main>
    </div>
  );
};

export default Eco;
