import { useCallback, useRef, useState, useContext } from "react";
import { AboutTexts } from "../../../texts/texts";
import { GlobalContext } from "../../Layout/Layout";
import "./style.css";

const OptionSlider = () => {
  const { language } = useContext(GlobalContext);

  const [activeBackground, setActiveBackground] = useState(1);

  const firstSlideRef = useRef(null);
  const secondSlideRef = useRef(null);

  const cleanUpTransition = useCallback((target1, target2) => {
    target2.classList.remove("active", "set");
    target1.removeEventListener("transitionend", cleanUpTransition);
  }, []);

  const transitionSlide = useCallback(
    (target1, target2) => {
      target1.classList.add("active", "set");
      target1.addEventListener(
        "transitionend",
        cleanUpTransition(target1, target2)
      );
    },
    [cleanUpTransition]
  );

  const changeActiveSlide = useCallback(
    (number) => {
      number === 1
        ? transitionSlide(firstSlideRef.current, secondSlideRef.current)
        : transitionSlide(secondSlideRef.current, firstSlideRef.current);

      setActiveBackground(number);
    },
    [transitionSlide]
  );

  return (
    <div className="component-option-slider">
      <div className="background-layer">
        <img
          className={activeBackground === 1 ? "active" : ""}
          src="/chedraui-textil-6-blur.jpg"
          alt=""
        />
        <img
          className={activeBackground === 2 ? "active" : ""}
          src="/chedraui-textil-7-blur.jpg"
          alt=""
        />
      </div>
      <div className="inner">
        <div className="options">
          <h3>{AboutTexts[10][language]}</h3>
          <ul>
            <li>
              <button
                className={activeBackground === 1 ? "active" : ""}
                onClick={() => changeActiveSlide(1)}
              >
                {AboutTexts[11][language]}
              </button>
            </li>
            <li>
              <button
                className={activeBackground === 2 ? "active" : ""}
                onClick={() => changeActiveSlide(2)}
              >
                {AboutTexts[12][language]}
              </button>
            </li>
          </ul>
        </div>
        <div className="slider">
          <div className="slider-content">
            <div className="slider-item active set" ref={firstSlideRef}>
              <img src="/chedraui-textil-6.jpg" alt="Fabric" />
            </div>
            <div className="slider-item two" ref={secondSlideRef}>
              <img src="/chedraui-textil-7.jpg" alt="Clothing" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionSlider;
