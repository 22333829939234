import { Link } from "react-router-dom";
import "./style.css";

const CardLink = ({ path, img, text }) => {
  return (
    <Link data-aos="fade-right" className="component-selling-point" to={path}>
      <div className="icon">
        <img src={img} alt="" />
      </div>
      <p>{text}</p>
    </Link>
  );
};

export default CardLink;
