import "./style.css";

const FabricItem = ({ img, title }) => {
  return (
    <div data-aos="fade-up" className="component-fabric-item">
      <div className="inner">
        <img src={img} alt="" />
      </div>
      <h5>{title}</h5>
    </div>
  );
};

export default FabricItem;
