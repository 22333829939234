import { Item } from "react-photoswipe-gallery";
import "./style.css";

const PrintItem = ({ img, subTitle }) => {
  return (
    <div className="component-print-item" data-aos="fade-up">
      <Item original={img} thumbnail={img} width="1200" height="1200" alt="">
        {({ ref, open }) => <img src={img} ref={ref} onClick={open} alt="" />}
      </Item>
      <p className="subtitle">{subTitle}</p>
    </div>
  );
};

export default PrintItem;
