import { useContext, useRef } from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery } from "react-photoswipe-gallery";
import Hero from "../../components/Hero/Hero";
import PictureBlock from "../../components/Print/PictureBlock/PictureBlock";
import PrintItem from "../../components/Print/PrintItem/PrintItem";
import "./style.css";
import { GlobalContext } from "../../components/Layout/Layout";
import { NavTexts, PrintTexts } from "../../texts/texts";
import CardLink from "../../components/Home/SellingPoint/CardLink";
import "aos/dist/aos.css";
import Aos from "aos";

const Print = () => {
  Aos.init();

  const { language } = useContext(GlobalContext);

  const { current: screenPrintCollection } = useRef([
    {
      img: "chedraui-textil-47.jpg",
      subTitle: "HD",
    },
    {
      img: "chedraui-textil-48.jpg",
      subTitle: "PUFF",
    },
    {
      img: "chedraui-textil-50.jpg",
      subTitle: "FLOCK",
    },
    {
      img: "chedraui-textil-51.jpg",
      subTitle: "GLITTER",
    },
    {
      img: "chedraui-textil-52.jpg",
      subTitle: "FOIL",
    },
    {
      img: "chedraui-textil-53.jpg",
      subTitle: "GLOW IN THE DARK",
    },
    {
      img: "chedraui-textil-60.jpg",
      subTitle: "Shimmer negro",
    },
    {
      img: "chedraui-textil-54.jpg",
      subTitle: "Caviar",
    },
    {
      img: "chedraui-textil-55.jpg",
      subTitle: "Tinta metálica",
    },
    {
      img: "chedraui-textil-56.jpg",
      subTitle: "Aplicación y estampado",
    },
    {
      img: "chedraui-textil-57.jpg",
      subTitle: "Plastisol",
    },
    {
      img: "chedraui-textil-58.jpg",
      subTitle: "Cristalina",
    },
  ]);

  const { current: embroideryCollection } = useRef([
    {
      img: "bordado1.jpg",
    },
    {
      img: "bordado2.jpg",
    },
    {
      img: "bordado3.jpg",
    },
    {
      img: "bordado4.jpg",
    },
    {
      img: "bordado5.jpg",
    },
    {
      img: "bordado6.jpg",
    },
    {
      img: "bordado7.jpg",
    },
    {
      img: "bordado8.jpg",
    },
    {
      img: "bordado9.jpg",
    },
    {
      img: "bordado10.jpg",
    },
    {
      img: "bordado11.jpg",
    },
    {
      img: "bordado12.jpg",
    },
    {
      img: "bordado13.jpg",
    },
        {
      img: "chedraui-textil-59.jpg",
    },
  ]);

  const sellingPointsLinks = [
    {
      path: "/production",
      img: "/icons/chedraui-textil-icon-1.svg",
      text: NavTexts[1][language],
    },
    {
      path: "/fabric",
      img: "/icons/chedraui-textil-icon-2.svg",
      text: NavTexts[2][language],
    },
    {
      path: "/print",
      img: "/icons/chedraui-textil-icon-3.svg",
      text: NavTexts[3][language],
    },
    {
      path: "/clothing",
      img: "/icons/chedraui-textil-icon-4.svg",
      text: NavTexts[4][language],
    },
    {
      path: "/eco",
      img: "/icons/chedraui-textil-icon-5.svg",
      text: NavTexts[5][language],
    },
  ];

  return (
    <div className="screen print">
      <Hero
        img="/chedraui-textil-42.jpg"
        title={PrintTexts[2][language]}
        subtitle={PrintTexts[3][language]}
      />
      <main>
        <div className="background-circle"></div>

        <ul className="selling-points-list">
          {sellingPointsLinks.map((sellingPoint, index) => (
            <CardLink
              key={index}
              path={sellingPoint.path}
              img={sellingPoint.img}
              text={sellingPoint.text}
            />
          ))}
        </ul>

        <section className="stepper-slider">
          <div className="stepper" data-aos="fade-left">
            <span>HD</span>
            <span className="separator"></span>
            <span>PUFF</span>
            <span className="separator"></span>
            <span>THERMOLINE</span>
            <span className="separator"></span>
            <span>FLOCK</span>
            <span className="separator"></span>
            <span>GLITTER</span>
            <span className="separator"></span>
            <span>FOIL</span>
            <span className="separator"></span>
            <span>GLOW IN THE DARK</span>
          </div>
          <PictureBlock />
        </section>
        <section className="print-collections">
          <article className="collection screen-print">
            <h3 data-aos="fade-right">
              {PrintTexts[4][language][0]}{" "}
              <span className="outline-white">
                {PrintTexts[4][language][1]}
              </span>
            </h3>
            <div className="content">
              <Gallery>
                {screenPrintCollection.map((item, index) => {
                  return (
                    <PrintItem
                      key={index}
                      img={item.img}
                      subTitle={item.subTitle}
                    />
                  );
                })}
              </Gallery>
            </div>
          </article>
          <article className="collection screen-print">
            <h3 data-aos="fade-right">{PrintTexts[5][language]} </h3>
            <div className="content">
              <Gallery>
                {embroideryCollection.map((item, index) => {
                  return (
                    <PrintItem
                      key={index}
                      img={item.img}
                      // subTitle={item.subTitle}
                    />
                  );
                })}
              </Gallery>
            </div>
          </article>
        </section>
      </main>
    </div>
  );
};

export default Print;
