import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { NavTexts } from "../../texts/texts";
import { GlobalContext } from "../Layout/Layout";
import { FooterTexts } from "../../texts/texts";
import "./style.css";

const Footer = () => {
  const { navCollection, menuIndex, changeMenuIndex, language } =
    useContext(GlobalContext);

  return (
    <footer className="component-footer">
      <div className="menu-social">
        <div className="footer-menu one">
          <h5>{FooterTexts[0][language]}</h5>
          <ul className="navigation">
            {navCollection.map((item, index) => {
              if (index >= 2 && index < 7) {
                return (
                  <li key={index + 1}>
                    <Link
                      to={item.url}
                      className={menuIndex === index ? "active" : ""}
                      onClick={() => changeMenuIndex(index)}
                    >
                      {item.copy}
                    </Link>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
        <div className="footer-menu two">
          <h5>{FooterTexts[1][language]}</h5>
          <ul className="navigation">
            <li>
              <Link
                to="/contact"
                className={menuIndex === 7 ? "active" : ""}
                onClick={() => changeMenuIndex(7)}
              >
                {NavTexts[6][language]}
              </Link>
            </li>
            <li>
              <Link
                to="/about-us"
                className={menuIndex === 1 ? "active" : ""}
                onClick={() => changeMenuIndex(1)}
              >
                {NavTexts[0][language]}
              </Link>
            </li>
            {/* <li>
              <Link to="/terms-and-conditions">Terms &amp; conditions</Link>
            </li> */}
          </ul>
        </div>
        <div className="address">
          <h5>{FooterTexts[2][language]}</h5>
          <p>
            Camino Nacional # 165- A. <br></br>
            Col. Ignacio Romero Vargas, 72120 <br></br>
            Puebla, Pue.
          </p>
        </div>
        {/* <ul className="social-links">
          <li className="fb">
            <a href=" ">
              <img src="/icons/facebook.svg" alt="facebook logo" />
            </a>
          </li>
          <li className="tw">
            <a href=" ">
              <img src="/icons/twitter.svg" alt="twitter logo" />
            </a>
          </li>
          <li className="ig">
            <a href=" ">
              <img src="/icons/instagram.svg" alt="instagram logo" />
            </a>
          </li>
          <li className="g+">
            <a href=" ">
              <img src="/icons/google+.svg" alt="google+ logo" />
            </a>
          </li>
        </ul> */}
      </div>
      <div className="footer-map-wrapper">
        <iframe
          title="maps-frame"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15083.32218419776!2d-98.24562!3d19.0711866!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc7dcf6b9d613%3A0x2b47949c4966dac8!2sCHEDRAUI%20TEXTIL%20SA%20DE%20CV!5e0!3m2!1sen!2smx!4v1658791526255!5m2!1sen!2smx"
          width="100%"
          height="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </footer>
  );
};

export default Footer;
