import Routing from "./Routing";
import "./styles/index.css";
import "aos/dist/aos.css";
import Aos from "aos";

function App() {
  Aos.init();

  document
    .querySelectorAll("img")
    .forEach((img) => img.addEventListener("load", () => Aos.refresh()));

  return (
    <div className="app">
      <Routing />
    </div>
  );
}

export default App;
