import { useRef, useContext } from "react";
import PictureGrid from "../../components/Clothing/PictureGrid/PictureGrid";
import PictureRow from "../../components/Clothing/PictureRow/PictureRow";
import Hero from "../../components/Hero/Hero";
import CardLink from "../../components/Home/SellingPoint/CardLink";
import { GlobalContext } from "../../components/Layout/Layout";
import { ClothingTexts, NavTexts } from "../../texts/texts";
import "./style.css";
import "aos/dist/aos.css";
import Aos from "aos";

const Clothing = () => {
  Aos.init();

  const { language } = useContext(GlobalContext);

  const RowTitles = [
    ClothingTexts[1][language],
    ClothingTexts[2][language],
    ClothingTexts[3][language],
    ClothingTexts[4][language],
    ClothingTexts[5][language],
  ];

  const { current: PictureRowCollection } = useRef([
    [
      "chedraui-textil-62.jpg",
      "chedraui-textil-63.jpg",
      "chedraui-textil-64.jpg",
      "chedraui-textil-65.jpg",
      "chedraui-textil-66.jpg",
    ],
    [
      "chedraui-textil-67.jpg",
      "chedraui-textil-68.jpg",
      "chedraui-textil-69.jpg",
      "chedraui-textil-70.jpg",
      "chedraui-textil-71.jpg",
    ],
    [
      "chedraui-textil-72.jpg",
      "chedraui-textil-73.jpg",
      "chedraui-textil-74.jpg",
      "chedraui-textil-75.jpg",
      "chedraui-textil-76.jpg",
    ],
    [
      "chedraui-textil-77.jpg",
      "chedraui-textil-78.jpg",
      "chedraui-textil-79.jpg",
      "chedraui-textil-80.jpg",
      "chedraui-textil-81.jpg",
    ],
    [
      "chedraui-textil-82.jpg",
      "chedraui-textil-83.jpg",
      "chedraui-textil-84.jpg",
      "chedraui-textil-85.jpg",
      "chedraui-textil-86.jpg",
    ],
  ]);

  const { current: PictureGridCollection } = useRef([
    "chedraui-textil-87.jpg",
    "chedraui-textil-88.jpg",
    "chedraui-textil-89.jpg",
    "chedraui-textil-90.jpg",
    "chedraui-textil-91.jpg",
    "chedraui-textil-92.jpg",
    "chedraui-textil-93.jpg",
    "chedraui-textil-94.jpg",
    "chedraui-textil-95.jpg",
    "chedraui-textil-96.jpg",
    "chedraui-textil-97.jpg",
    "chedraui-textil-98.jpg",
    "chedraui-textil-99.jpg",
    "chedraui-textil-100.jpg",
    "chedraui-textil-101.jpg",
    "chedraui-textil-102.jpg",
    "chedraui-textil-103.jpg",
    "chedraui-textil-104.jpg",
    "chedraui-textil-105.jpg",
    "chedraui-textil-106.jpg",
    "chedraui-textil-107.jpg",
    "chedraui-textil-108.jpg",
    "chedraui-textil-109.jpg",
    "chedraui-textil-110.jpg",
  ]);

  const sellingPointsLinks = [
    {
      path: "/production",
      img: "/icons/chedraui-textil-icon-1.svg",
      text: NavTexts[1][language],
    },
    {
      path: "/fabric",
      img: "/icons/chedraui-textil-icon-2.svg",
      text: NavTexts[2][language],
    },
    {
      path: "/print",
      img: "/icons/chedraui-textil-icon-3.svg",
      text: NavTexts[3][language],
    },
    {
      path: "/clothing",
      img: "/icons/chedraui-textil-icon-4.svg",
      text: NavTexts[4][language],
    },
    {
      path: "/eco",
      img: "/icons/chedraui-textil-icon-5.svg",
      text: NavTexts[5][language],
    },
  ];

  return (
    <div className="screen clothing">
      <Hero img="/chedraui-textil-61.jpg" title={ClothingTexts[0][language]} />
      <main>
        <div className="background-circle top"></div>
        <div className="background-circle bottom"></div>
        <ul className="selling-points-list">
          {sellingPointsLinks.map((sellingPoint, index) => (
            <CardLink
              key={index}
              path={sellingPoint.path}
              img={sellingPoint.img}
              text={sellingPoint.text}
            />
          ))}
        </ul>
        <section className="clothing-section">
          {RowTitles.map((item, index) => {
            return (
              <PictureRow
                title={item}
                key={index}
                collection={PictureRowCollection[index]}
              />
            );
          })}
        </section>
        <section className="clothing-gallery">
          <PictureGrid
            title={ClothingTexts[6][language]}
            collection={PictureGridCollection}
          />
        </section>
      </main>
    </div>
  );
};

export default Clothing;
