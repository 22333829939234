import { useContext, useMemo } from "react";
import Hero from "../../components/Hero/Hero";
import KeyPoint from "../../components/Production/KeyPoint/KeyPoint";
import { NavTexts, ProductionTexts } from "../../texts/texts";
import "./style.css";
import { GlobalContext } from "../../components/Layout/Layout";
import CardLink from "../../components/Home/SellingPoint/CardLink";
import "aos/dist/aos.css";
import Aos from "aos";

const Production = () => {
  Aos.init();

  const { language } = useContext(GlobalContext);

  const keyPointsData = useMemo(
    () => [
      {
        title: ProductionTexts[1][language],
        copyOne: ProductionTexts[2][language],
        listContent: ProductionTexts[3][language],
        copyTwo: ProductionTexts[4][language],
        picture: "chedraui-textil-12.jpg",
      },
      {
        title: ProductionTexts[5][language],
        copyOne: ProductionTexts[6][language],
        listContent: ProductionTexts[7][language],
        copyTwo: ProductionTexts[8][language],
        picture: "chedraui-textil-13.jpg",
      },
      {
        title: ProductionTexts[16][language],
        copyOne: ProductionTexts[21][language],
        picture: "chedraui-textil-tenido-acabado.jpg",
      },
      {
        title: ProductionTexts[9][language],
        copyOne: ProductionTexts[10][language],
        picture: "chedraui-textil-14.jpg",
        link: "fabric",
      },
      {
        title: ProductionTexts[11][language],
        copyOne: ProductionTexts[12][language],
        picture: "chedraui-textil-6.jpg",
      },
      {
        title: ProductionTexts[17][language],
        copyOne: ProductionTexts[18][language],
        picture: "/print-block.jpg",
        link: "print",
      },
      {
        title: ProductionTexts[14][language],
        copyOne: ProductionTexts[15][language],
        picture: "coser.jpg",
      },
      {
        title: ProductionTexts[19][language],
        copyOne: ProductionTexts[20][language],
        picture: "coser.jpg",
      },
    ],
    [language]
  );

  const sellingPointsLinks = [
    {
      path: "/production",
      img: "/icons/chedraui-textil-icon-1.svg",
      text: NavTexts[1][language],
    },
    {
      path: "/fabric",
      img: "/icons/chedraui-textil-icon-2.svg",
      text: NavTexts[2][language],
    },
    {
      path: "/print",
      img: "/icons/chedraui-textil-icon-3.svg",
      text: NavTexts[3][language],
    },
    {
      path: "/clothing",
      img: "/icons/chedraui-textil-icon-4.svg",
      text: NavTexts[4][language],
    },
    {
      path: "/eco",
      img: "/icons/chedraui-textil-icon-5.svg",
      text: NavTexts[5][language],
    },
  ];

  return (
    <div className="screen production">
      <Hero
        img="/chedraui-textil-11.jpg"
        title={ProductionTexts[0][language]}
      />
      <main className="main-production">
        <div className="background-circle"></div>
        <ul className="selling-points-list">
          {sellingPointsLinks.map((sellingPoint, index) => (
            <CardLink
              key={index}
              path={sellingPoint.path}
              img={sellingPoint.img}
              text={sellingPoint.text}
            />
          ))}
        </ul>
        <section className="key-points">
          <article className="key-points-texts">
            {keyPointsData.map((item, index) => {
              if (index <= 1) {
                return (
                  <KeyPoint
                    key={index + 1}
                    index={index}
                    title={item.title}
                    copyOne={item.copyOne}
                    listContent={item.listContent}
                    copyTwo={item.copyTwo}
                    picture={item.picture}
                  />
                );
              } else if (index === 2) {
                return (
                  <KeyPoint
                    key={index + 1}
                    index={index}
                    title={item.title}
                    copyOne={item.copyOne}
                    picture={item.picture}
                    link={item.link}
                  />
                );
              } else {
                return (
                  <KeyPoint
                    key={index + 1}
                    index={index}
                    title={item.title}
                    copyOne={item.copyOne}
                    picture={item.picture}
                  />
                );
              }
            })}
          </article>
        </section>
        <div className="big-needle">
          <img src="icons/needle.svg" alt="" />
        </div>
      </main>
    </div>
  );
};

export default Production;
