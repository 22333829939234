import { Link, Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./style.css";
import { NavTexts } from "../../texts/texts";
import "aos/dist/aos.css";
import Aos from "aos";

export const GlobalContext = createContext({
  menuIndex: 0,
  changeMenuIndex: () => {},
  navCollection: [],
  language: "english",
  changeLanguage: () => {},
});

const Layout = () => {
  const location = useLocation();

  const [menuIndex, setMenuIndex] = useState(
    JSON.parse(sessionStorage.getItem("menuIndex")) ?? -1
  );
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [language, setLanguage] = useState("english");

  const changeLanguage = useCallback(() => {
    language === "english" ? setLanguage("spanish") : setLanguage("english");
  }, [language]);

  const navCollection = useMemo(
    () => [
      {
        url: "/",
        copy: NavTexts[7][language],
      },
      {
        url: "/about-us",
        copy: NavTexts[0][language],
      },
      {
        url: "/production",
        copy: NavTexts[1][language],
      },
      {
        url: "/fabric",
        copy: NavTexts[2][language],
      },
      {
        url: "/print",
        copy: NavTexts[3][language],
      },
      {
        url: "/clothing",
        copy: NavTexts[4][language],
      },
      {
        url: "/eco",
        copy: NavTexts[5][language],
      },
      {
        url: "/contact",
        copy: NavTexts[6][language],
      },
    ],
    [language]
  );

  const preventScroll = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const changeMenuIndex = useCallback(
    (index) => {
      setMenuIndex(index);
      sessionStorage.setItem("menuIndex", JSON.stringify(index));
    },
    [setMenuIndex]
    
  );

  useEffect(() => {
    openMobileNav
      ? document.body.addEventListener("wheel", preventScroll, {
          passive: false,
        })
      : document.body.removeEventListener("wheel", preventScroll);
  }, [openMobileNav, preventScroll]);

  useEffect(() => {
    setOpenMobileNav(false);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        menuIndex,
        changeMenuIndex,
        navCollection,
        language,
        changeLanguage,
      }}
    >
      <div className="component-layout">
        <Header />
        <>
          <div className="mobile-navigation">
            <Link to="/" className="main-link">
              <img src="/logo.svg" alt="logo" />
            </Link>
            <div className="action-navigation">
              <button className="language" onClick={changeLanguage}>
                <img src="/icons/globe.svg" alt="change language" />
              </button>
              <button
                className="menu-button"
                onClick={() => setOpenMobileNav(true)}
              >
                <img src="/icons/hamburger.svg" alt="menu icon" />
              </button>
            </div>
          </div>
          {openMobileNav && (
            <div className="links-mobile">
              <div className="close-row">
                <button
                  className="close-menu"
                  onClick={() => setOpenMobileNav(false)}
                >
                  <img src="/icons/close.svg" alt="close icon" />
                </button>
              </div>
              <nav>
                <ul>
                  {navCollection.map(
                    (item, index) => (
                      <li key={index}>
                        <Link
                          to={item.url}
                          className={index === menuIndex ? "active" : ""}
                          onClick={() => changeMenuIndex(index)}
                        >
                          {item.copy}
                        </Link>
                      </li>
                    ),

                    []
                  )}
                </ul>
              </nav>
            </div>
          )}
        </>

        <Outlet />
        <Footer />
      </div>
    </GlobalContext.Provider>
  );
};

export default Layout;
