export const NavTexts = [
  {
    english: "About Us",
    spanish: "Nosotros",
  },
  {
    english: "Production",
    spanish: "Producción",
  },
  {
    english: "Fabrics",
    spanish: "Telas",
  },
  {
    english: "Print & Embroidery",
    spanish: "Serigrafía & Bordado",
  },
  {
    english: "Clothing",
    spanish: "Ropa",
  },
  {
    english: "Eco",
    spanish: "Ecológico",
  },
  {
    english: "Contact",
    spanish: "Contacto",
  },
  {
    english: "Home",
    spanish: "Inicio",
  },
];

export const HomeTexts = [
  {
    english:
      "Chedraui Textil is a totally vertical manufacturer specializing in a wide array of knot fabrics with 50 years of experience in the textile Industry in Mexico.",
    spanish:
      "Chedraui Textil es un fabricante vertical especializado en tejidos de punto con 50 años de experiencia en la industria textil en México.",
  },
  {
    english: ["Fashion &", " Basic wear"],
    spanish: ["Moda &", " Ropa básica"],
  },
  {
    english: ["MY WISH FOR MY SKIN"],
    spanish: ["MY WISH FOR MY SKIN"],
  },
  {
    english: ["WEAR CONFORT"],
    spanish: ["WEAR CONFORT"],
  },
];

export const AboutTexts = [
  {
    english:
      "The company was founded in 1995 and has mor than 50 years' experience in both México and the USA. It has a very friendly atmosphere that makes workers have a good relationship with each other and can work in a healthy environment. The company has been exporting and manufacturing domestically for many years. It specializes in 'Just in time' while maintaining quality and price.",
    spanish:
      "La empresa se fundó en 1995 y cuenta con 50 años de experiencia en la industria textil. Tiene un ambiente muy agradable que hace que los trabajadores tengan una buena relación entre ellos y puedan trabajar en un entorno saludable. La empresa lleva muchos años exportando y fabricando en el país. Está especializada en el 'Just in time' manteniendo la calidad y el precio.",
  },
  {
    english:
      "Within the factory we have the processes of knitting, cutting, printing, tailoring, quality, packaging, and shipping. We have worked with multinational companies that have very strict regimes",
    spanish:
      "Dentro de la fábrica tenemos los procesos de tejido, corte, impresión, confección, calidad, embalaje y envío. Hemos trabajado con empresas multinacionales que tienen regímenes muy estrictos",
  },
  {
    english: "facilities",
    spanish: "instalaciones",
  },
  {
    english:
      "We are in Puebla, México and we are totally vertical from the bale of cotton to the finished product, floor ready. We are USMCA compliant",
    spanish:
      "Estamos ubicados en Puebla, México, contamos con tecnología para el hilado, tejido, acabado, corte, estampado y empaque de prendas.",
  },
  {
    english: "processes",
    spanish: "procesos",
  },
  {
    english:
      "In each one of these processes, we maintain the highest standards of quality for each of our products using highly specialized equipment operated by trained personal.",
    spanish:
      "En cada uno de estos procesos mantenemos los más altos estándares de calidad para cada uno de nuestros productos utilizando equipos altamente especializados operados por personal capacitado.",
  },
  {
    english: "export",
    spanish: "exportación",
  },
  {
    english:
      "We design full packages and programs for companies to export. Our company has been evaluated and certified for this.",
    spanish:
      "Diseñamos paquetes completos y programas para empresas con el fin de exportar. Nuestra empresa ha sido evaluada y certificada para ello.",
  },
  {
    english: "GUARANTEED QUALITY",
    spanish: "CALIDAD GARANTIZADA",
  },
  {
    english: "50 years in the textile industry",
    spanish: "50 años en la industria textil",
  },
  {
    english: "WHAT WE DO?",
    spanish: "¿QUÉ HACEMOS?",
  },
  {
    english: "FABRIC",
    spanish: "TELA",
  },
  {
    english: "CLOTHING",
    spanish: "ROPA",
  },
];

export const ProductionTexts = [
  {
    english: "PRODUCTION",
    spanish: "PRODUCCIÓN",
  },
  {
    english: ["Spining"],
    spanish: ["Hilado"],
  },
  {
    english: "Our yarn suppliers can produce:",
    spanish: "Nuestros proovedores de hilo pueden producir:",
  },
  {
    english: [
      "100% Combed Cotton",
      "50% Combed Cotton 50% Polyester",
      "100% Carded Cotton",
      "50% Carded Cotton 50% Polyester",
      "100% Open-end Cotton",
      "50% Open-end Cotton 50% Polyester",
      "Triblend 50% Polyester 37% Cotton 13% R.V.",
    ],
    spanish: [
      "100% Algodón peinado",
      "50% Algodón peinado 50% Polyester",
      "100% Algodón Cardado",
      "50% Algodón cardado 50% Polyester",
      "100% Algodón Open-end ",
      "50% Algodón Open-end 50% Polyester",
      "Triblend -50% Polyester 37% Algodón 13% R.V.",
    ],
  },
  {
    english:
      "They are BCI cotton certified. They handle a wide range of yarn titles, working from 18s to 40s. Its monthly manufacturing capacity is up to 240,000kg.",
    spanish:
      "Tienen certificación de algodón BCI. Manejan una amplia gama de titulos de hilo, trabajan desde titulo 18s hasta 40s. Su capacidad de fabricación mensual es de hasta 240,000kg.",
  },
  {
    english: ["Knitting"],
    spanish: ["Tejido"],
  },
  {
    english:
      "We can produce and offer knit fabric in a wide variety of weights and diameters such as and continue the list as you have it.",
    spanish: "Podemos fabricar tejidos diferentes como:",
  },
  {
    english: [
      "Fleece",
      "Jersey",
      "Jersey Spandex",
      "French terry",
      "French terry with spandex",
      "Baby terry",
      "Rib 1x1-2x1-2x2",
      "Cardigan",
      "Pique",
      "Thermal",
      "Mini Thermal",
      "Interlock",
      "Fantasy knits",
      "Jacquard",
      "Yarn Dye Stripes",
    ],
    spanish: [
      "Felpa",
      "Jersey (chifón)",
      "Chifón con spandex",
      "French Terry",
      "French Terry con spandex",
      "Baby Terry",
      "Rib 1x1-2x1-2x2",
      "Baby Rib",
      "Cardigan",
      "Piqué",
      "Termal - Wafle",
      "Mini Termal",
      "Interlock",
      "Telas de Fantasía",
      "Jacquard",
      "Rayas con hilo pre-teñido",
    ],
  },
  {
    english: "",
    spanish: "",
  },
  {
    english: ["Roller", "Print"],
    spanish: ["Impresión de", "Rodillos"],
  },
  {
    english:
      "Our Roller Print supplier complies with all the official Mexican standards. It handles techniques such as pigment, lacquer, discharge, glitter and glitter bath, among others.",
    spanish:
      "Nuestro proveedor de Roller Print o Impresion en rodillo cumple con todas las normas oficiales mexicanas. Maneja técnicas como pigmento, laca, descarga, glitter y baño de glitter, entre otras.",
  },
  {
    english: ["Cutting"],
    spanish: ["Corte"],
  },
  {
    english:
      "We have a cutting department that has 5 tables. We cut tubular and open fabrics with digital molds.",
    spanish:
      "Tenemos un departamento de corte que cuenta con 5 mesas. Cortamos telas tubulares y abiertas con moldes digitales.",
  },
  {
    english: "See more",
    spanish: "Conocer más",
  },
  {
    english: ["Sewing"],
    spanish: ["Confección"],
  },
  {
    english:
      "It is the assembly of the pieces of a garment through stitches made with industrial machines. We work straight, Over Lock, Cover Stitch, Elbow Machine, buttonhole and button, placement of pad printing, transfer and labeling. Our monthly manufacturing capacity is up to 240,000  garments.",
    spanish:
      "Es el ensamble de las piezas de una prenda a través de puntadas elaboradas con maquinias industriales. Trabajamos recta, Over Lock, Cover Stitch, Máquina de Codo, Ojal y botón, colocación de tampografía, transfer y etiquetado. Nuestra capacidad de fabricación mensual es de hasta 240,000 prendas básicas y de moda.",
  },
  {
    english: ["Teñido y Acabado"],
    spanish: ["Teñido y Acabado"],
  },
  {
    english: ["Screen Print"],
    spanish: ["Serigrafía y bordado"],
  },
  {
    english: [
      "Our printing supplier has 6 printing machines with up to 18 inks, a wide range of techniques and a capacity of 60,000 garments per week. they also have heat transfer and a wide range of techniques. They are certified with several licenses from recognized brands in the market.",
    ],
    spanish: [
      "Nuestro proveedor de estampado cuenta con 6 máquinas de estampado de hasta 18 tintas, una amplia gama de técnicas y una capacidad de 60,000 prendas semanales. También cuenta con heat transfer. Cuenta con estampado de selección a color y separación a color. Esta certificado con varias licencias de marcas reconocidas en el mercado.",
    ],
  },
  {
    english: ["Packaging and transportation"],
    spanish: ["Empaque y Transporte"],
  },
  {
    english: [
      "We have custom packaging and shipping to anywhere in the world.",
    ],
    spanish: [
      "Contamos con empaque personalizado y envío a cualquier parte del mundo.",
    ],
  },
  {
    english: [
      "The fabric is sent to our dyeing and finishing suppliers who comply with all official Mexican standards.",
    ],
    spanish: [
      "La tela en crudo se manda a maquilar a nuestros proveedores de teñido y acabado que cumplen con todas las normas oficiales mexicanas.",
    ],
  },
];

export const FabricTexts = [
  {
    english: "FABRICS",
    spanish: "FABRICACIÓN",
  },
  {
    english:
      "The type of fabrics varies by the fibers, the fabric producing them, and finishing techniques. Fabrics can also be made differently based on the sustainable.",
    spanish:
      "El tipo de frabricación varía según las fibras, el tejido que las produce y las técnicas de acabado.",
  },
  {
    english: ["Our", "Fabrics"],
    spanish: ["Nuestras", " Telas"],
  },
  {
    english: ["Roller ", "Print"],
    spanish: ["Impresion ", " Rotativa"],
  },
];

export const PrintTexts = [
  {
    english: ["Screen", "Print"],
    spanish: ["Impresión", "Serigráfica"],
  },
  {
    english:
      "Our printing supplier has 6 printing machines with up to 18 inks, a wide range of techniques and a capacity of 60,000 garments per week. they also have heat transfer and a wide range of techniques. They are certified with several licenses from recognized brands in the market.",
    spanish:
      "Nuestro proveedor de estampado cuenta con 6 máquinas de estampado de hasta 18 tintas, una amplia gama de técnicas y una capacidad de 60,000 prendas semanales. También cuenta con heat transfer. Cuenta con estampado de selección a color y separación a color. Esta certificado con varias licencias de marcas reconocidas en el mercado.",
  },
  {
    english: ["SCREEN PRINT & EMBROIDERY "],
    spanish: ["SERIGRAFÍA & BORDADO"],
  },
  {
    english: [""],
    spanish: [""],
  },
  {
    english: ["Screen", " Print"],
    spanish: ["Serigrafía"],
  },
  {
    english: ["Embroidery"],
    spanish: ["Bordado"],
  },
];

export const ClothingTexts = [
  {
    english: ["Clothing"],
    spanish: ["Ropa"],
  },
  {
    english: ["Baby", "clothing"],
    spanish: ["Ropa de", " Bebé"],
  },
  {
    english: ["Kids", "clothing"],
    spanish: ["Ropa de", " Niño"],
  },
  {
    english: ["Teens", "clothing"],
    spanish: ["Ropa de", " Adolescentes"],
  },
  {
    english: ["Women's", "clothing"],
    spanish: ["Ropa de", " Mujer"],
  },
  {
    english: ["Men's", "clothing"],
    spanish: ["Ropa de", " Hombre"],
  },
  {
    english: ["Gallery"],
    spanish: ["Galería"],
  },
];

export const EcoTexts = [
  {
    english: "Turning recycled cotton into a fashion item.",
    spanish: "Convertimos el algodón reciclado en un artículo de moda",
  },
  {
    english: ["Ecological", "Sustainable"],
    spanish: ["Sostenibilidad", "Ecológica"],
  },
  {
    english:
      "ECO-FRIENDLY Textiles are changing the fashion industry for good.",
    spanish:
      "Los textiles ecológicos están cambiando la industria de la moda para siempre.",
  },
  {
    english: "ECO",
    spanish: "ECOLÓGICO",
  },
  {
    english:
      "Various types of recycled fabrics are made from pet and recycled fabrics. We also have stardard's certificate",
    spanish:
      "Se hacen varios tipos de telas reciclados a partir de  pet y telas recicladas. También contamos con certificado de stardard's",
  },
];

export const ContactTexts = [
  {
    english: "CONTACT",
    spanish: "CONTACTO",
  },
  {
    english: "Contact",
    spanish: "Contacto",
  },
  {
    english: "E-mail us",
    spanish: "Envíanos un correo electrónico",
  },
  {
    english: "Sales",
    spanish: "Ventas",
  },
  {
    english: "Customer Support",
    spanish: "Atención al cliente",
  },
  {
    english: "General Administration",
    spanish: "Administración General",
  },
  {
    english: "Contact us",
    spanish: "Contáctanos",
  },
  {
    english: "Location",
    spanish: "Ubicación",
  },
  {
    english: "Send us your comments!",
    spanish: "¡Envíanos tus comentarios!",
  },
  {
    english: "write your name",
    spanish: "Tu nombre",
  },
  {
    english: "E-mail*",
    spanish: "Correo*",
  },
  {
    english: "Phone number*",
    spanish: "Número de celular*",
  },
  {
    english: "Message",
    spanish: "Mensaje",
  },
  {
    english: "Send",
    spanish: "Enviar",
  },
  {
    english: "Reception",
    spanish: "Recepción",
  },
];

export const FooterTexts = [
  {
    english: "What we do",
    spanish: "Qué hacemos",
  },
  {
    english: "Contact",
    spanish: "Contacto",
  },
  {
    english: "Location",
    spanish: "Ubicación",
  },
];
