import { useRef, useContext } from "react";
import { ProductionTexts } from "../../../texts/texts";
import { GlobalContext } from "../../Layout/Layout";
import "./style.css";

const KeyPoint = ({
  index,
  title,
  copyOne,
  listContent,
  copyTwo,
  picture,
  link,
}) => {
  const { current: classNames } = useRef(["one", "two", "three", "four"]);
  const { language } = useContext(GlobalContext);

  return (
    <div className="component-key-point" data-aos="fade-up">
      <div className={`point-line ${classNames[index]}`}></div>
      <div className="part left">
        <div className="content">
          <h4 className="title">
            {title[0]}
            {title[1] && <span className="outline-white">{title[1]}</span>}
          </h4>
          <div className="text-content">
            <p className="copy first">{copyOne}</p>
            {listContent && (
              <ul className="key-points-list">
                {listContent.map((item, index) => (
                  <li key={index + 1}>{item}</li>
                ))}
              </ul>
            )}
            {copyTwo && <p className="copy second">{copyTwo}</p>}
          </div>
          {link && (
            <a href={link}>
              {ProductionTexts[13][language]}{" "}
              <span className="arrow">{ProductionTexts[13][language]}</span>
            </a>
          )}
        </div>
      </div>
      <div className="part right">
        <img data-aos="zoom-in" src={picture} alt="" />
      </div>
    </div>
  );
};

export default KeyPoint;
