import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GlobalContext } from "../Layout/Layout";
import "./style.css";
import "aos/dist/aos.css";
import Aos from "aos";

const Header = () => {
  Aos.init();

  const location = useLocation().pathname;

  const { menuIndex, changeMenuIndex, navCollection, changeLanguage } =
    useContext(GlobalContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const contactLoc = "/contact";

  const toggleMenu = useCallback((value) => {
    setIsMenuOpen(value);
  }, []);

  useEffect(() => {
    toggleMenu(false);
  }, [location, toggleMenu]);

  useEffect(() => {
    const func = (e) => {
      e.target.className !== "menu-button" &&
        e.target.className !== "close-menu" &&
        toggleMenu(false);
    };
    document.body.addEventListener("click", func);
    return () => document.body.removeEventListener("click", func);
  }, [toggleMenu]);

  return (
    <header className="component-header">
      <div className="spacer"></div>
      <Link className="main-link" to="/" onClick={() => changeMenuIndex(0)}>
        {location === contactLoc ? (
          <img data-aos="fade-down" src="/logo-black.svg" alt="logo" />
        ) : (
          <img data-aos="fade-down" src="/logo.svg" alt="logo" />
        )}
      </Link>
      <div className="action-navigation" data-aos="fade-left">
        <button className="language" onClick={changeLanguage}>
          <img
            src={
              location === contactLoc
                ? "/icons/globe-black.svg"
                : "/icons/globe.svg"
            }
            alt="globe icon"
          />
        </button>
        <Link className="about-link" to="about-us">
          <img
            src={
              location === contactLoc
                ? "/icons/about-us-black.png"
                : "/icons/about-us-white.png"
            }
            alt="globe icon"
          />
        </Link>
        <Link className="contact-link" to="contact">
          <img
            src={
              location === contactLoc
                ? "/icons/contact-black.png"
                : "/icons/contact-white.png"
            }
            alt="globe icon"
          />
        </Link>
        <button className="menu-button" onClick={() => toggleMenu(true)}>
          <img
            src={
              location === contactLoc
                ? "/icons/hamburger-black.svg"
                : "/icons/hamburger.svg"
            }
            alt="menu icon"
          />
        </button>
      </div>
      <nav
        className={`header-navigation ${isMenuOpen ? "active" : ""} ${
          location === contactLoc ? "with-shadow" : ""
        }`}
      >
        <div className="close-row">
          <button className="close-menu" onClick={() => toggleMenu(false)}>
            <img src="/icons/close-black.svg" alt="" />
          </button>
        </div>
        <ul className="navigation-menu">
          {navCollection.map((item, index) => {
            return (
              <li key={index} className={index === menuIndex ? "active" : ""}>
                <Link to={item.url} onClick={() => changeMenuIndex(index)}>
                  {item.copy}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
