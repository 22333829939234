import { Gallery, Item } from "react-photoswipe-gallery";
import "./style.css";

const PictureRow = ({ title, collection }) => {
  return (
    <div className="component-picture-row">
      <h3 data-aos="fade-right">
        {title[0]}
        {title[1] && <span className="outline-white"> {title[1]}</span>}
      </h3>
      <div className="pictures">
        <Gallery>
          {collection.map((item, index) => {
            return (
              <Item
                key={index + 1}
                original={item}
                thumbnail={item}
                width="1200"
                height="900"
                alt=""
              >
                {({ ref, open }) => (
                  <img
                    data-aos="fade-up"
                    src={item}
                    ref={ref}
                    onClick={open}
                    alt=""
                  />
                )}
              </Item>
            );
          })}
        </Gallery>
      </div>
    </div>
  );
};

export default PictureRow;
