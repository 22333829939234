import { useContext } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { PrintTexts } from "../../../texts/texts";
import "./style.css";
import { GlobalContext } from "../../Layout/Layout";

const PictureBlock = () => {
  const { language } = useContext(GlobalContext);

  return (
    <div className="component-picture-block" data-aos="zoom-in">
      <div className="pictures">
        <div className="three-column">
          <Gallery>
            <Item
              original="/chedraui-textil-43.jpg"
              thumbnail="/chedraui-textil-43.jpg"
              width="1200"
              height="1200"
              alt="">
              {({ ref, open }) => (
                <img
                  src="/chedraui-textil-43.jpg"
                  ref={ref}
                  onClick={open}
                  alt=""
                />
              )}
            </Item>
            <Item
              original="/chedraui-textil-44.jpg"
              thumbnail="/chedraui-textil-44.jpg"
              width="1200"
              height="1200"
              alt="">
              {({ ref, open }) => (
                <img
                  src="/chedraui-textil-44.jpg"
                  ref={ref}
                  onClick={open}
                  alt=""
                />
              )}
            </Item>
            <Item
              original="/chedraui-textil-45.jpg"
              thumbnail="/chedraui-textil-45.jpg"
              width="1200"
              height="1200"
              alt="">
              {({ ref, open }) => (
                <img
                  src="/chedraui-textil-45.jpg"
                  ref={ref}
                  onClick={open}
                  alt=""
                />
              )}
            </Item>
          </Gallery>
        </div>
        <div className="main-picture">
          <Gallery>
            <Item
              original="/chedraui-textil-46.jpg"
              thumbnail="/chedraui-textil-46.jpg"
              width="1200"
              height="1200"
              alt="">
              {({ ref, open }) => (
                <img
                  src="/chedraui-textil-46.jpg"
                  ref={ref}
                  onClick={open}
                  alt=""
                />
              )}
            </Item>
          </Gallery>
        </div>
      </div>
      <div className="info-text">
        <div className="text-item one">
          <h3>
            {PrintTexts[0][language][0]}{" "}
            <span className="outline-white">{PrintTexts[0][language][1]}</span>
          </h3>
          <p>{PrintTexts[1][language]}</p>
        </div>
        {/* <div className="text-item two">
          <h3>Roller print</h3>
          <p>
            Roller printing is a print method where printing design is produced
            by engraved rollers. This method of producing attractive designs is
            relatively inexpensive when compared with any and method it is
            machine counterpart of block printing.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default PictureBlock;
