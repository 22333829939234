import "./style.css";

const PictureTextBlock = ({ order, title, copy, img }) => {
  return (
    <div data-aos="fade-up" className={`component picture-text-block ${order}`}>
      <div className="title">
        <h2>{title}</h2>
      </div>
      <div className="copy">
        <p>{copy}</p>
      </div>
      <div className="picture">
        <div className="inner">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PictureTextBlock;
