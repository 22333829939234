import "./style.css";

const Hero = ({ isMobile, videoSrc, img, title, title2, subtitle }) => {
  return (
    <section className="component-hero">
      {videoSrc !== null && (
        <video className="full-screen-vid" loading="lazy" muted autoPlay loop>
          <source src={`${videoSrc}`} type="video/mp4" />
        </video>
      )}
      {!videoSrc && img && <img src={img} alt="" />}
      <div className="layer"></div>
      <h1 className="title" data-aos="zoom-in">
        {title}
      </h1>
      {title2 && (
        <h1 data-aos="zoom-in" className="title">
          {title2}
        </h1>
      )}
      {/* <h4 data-aos="fade-up" className="subtitle">
        {subtitle}
      </h4> */}
    </section>
  );
};

export default Hero;
