import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import "./style.css";

const PicturesCard = ({ collection }) => {
  return (
    <div className="component-pictures-card" data-aos="zoom-in">
      <Gallery>
        <div className="row one">
          {collection.map((item, index) => {
            return (
              index <= 3 && (
                <Item
                  key={index}
                  className="picture"
                  original={item}
                  thumbnail={item}
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img src={item} ref={ref} onClick={open} alt="" />
                  )}
                </Item>
              )
            );
          })}
        </div>
        <div className="row two">
          {collection.map((item, index) => {
            return (
              index >= 4 &&
              index <= 7 && (
                <Item
                  key={index}
                  className="picture"
                  original={item}
                  thumbnail={item}
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img src={item} ref={ref} onClick={open} alt="" />
                  )}
                </Item>
              )
            );
          })}
        </div>
        <div className="row three">
          {collection.map((item, index) => {
            return (
              index >= 8 && (
                <Item
                  key={index}
                  className="picture"
                  original={item}
                  thumbnail={item}
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img src={item} ref={ref} onClick={open} alt="" />
                  )}
                </Item>
              )
            );
          })}
        </div>
      </Gallery>
    </div>
  );
};

export default PicturesCard;
