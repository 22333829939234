import { useContext, useEffect, useState } from "react";
import Hero from "../../components/Hero/Hero";
import CardLink from "../../components/Home/SellingPoint/CardLink";
import { GlobalContext } from "../../components/Layout/Layout";
import { HomeTexts, NavTexts } from "../../texts/texts";
import "./style.css";
import "aos/dist/aos.css";
import Aos from "aos";

const Home = () => {
  const { changeMenuIndex, language } = useContext(GlobalContext);

  const mobileQuery = window.matchMedia("(max-width: 1120px)");

  // eslint-disable-next-line no-unused-vars
  const [isMobile, setIsMobile] = useState(mobileQuery.matches);

  // eslint-disable-next-line no-unused-vars
  const [videoSrc, setVideoSrc] = useState(
    isMobile ? null : "/Chedraui-Textil-Textless.mp4"
  );

  const sellingPointsLinks = [
    {
      path: "/production",
      img: "/icons/chedraui-textil-icon-1.svg",
      text: NavTexts[1][language],
    },
    {
      path: "/fabric",
      img: "/icons/chedraui-textil-icon-2.svg",
      text: NavTexts[2][language],
    },
    {
      path: "/print",
      img: "/icons/chedraui-textil-icon-3.svg",
      text: NavTexts[3][language],
    },
    {
      path: "/clothing",
      img: "/icons/chedraui-textil-icon-4.svg",
      text: NavTexts[4][language],
    },
    {
      path: "/eco",
      img: "/icons/chedraui-textil-icon-5.svg",
      text: NavTexts[5][language],
    },
  ];

  useEffect(() => {
    changeMenuIndex(0);
  });

  Aos.init();

  return (
    <div className="screen home">
      <Hero
        isMobile={isMobile}
        videoSrc={videoSrc}
        img="/chedraui-textil-123.jpg"
        title={HomeTexts[2][language]}
        title2={HomeTexts[3][language]}
        subtitle={HomeTexts[0][language]}
      />
      <main>
        <div className="background-circle top"></div>
        <div className="background-circle bottom"></div>
        <section className="selling-points">
          <h5 data-aos="fade-right" className="section-title">
            {HomeTexts[1][language][0]}
            <span className="outline-white">{HomeTexts[1][language][1]}</span>
          </h5>
          <ul className="selling-points-list">
            {sellingPointsLinks.map((sellingPoint, index) => (
              <CardLink
                key={index}
                path={sellingPoint.path}
                img={sellingPoint.img}
                text={sellingPoint.text}
              />
            ))}
          </ul>
          <article className="selling-points-pictures">
            <div className="picture" data-aos="fade-up">
              <div className="content">
                <img src="/chedraui-textil-2.jpg" alt="" />
              </div>
            </div>
            <div className="picture" data-aos="fade-up">
              <div className="content">
                <img src="/chedraui-textil-3.jpg" alt="" />
              </div>
            </div>
            <div className="picture" data-aos="fade-up">
              <div className="content">
                <img src="/chedraui-textil-4.jpg" alt="" />
              </div>
            </div>
            <div className="picture" data-aos="fade-up">
              <div className="content">
                <img src="/chedraui-textil-5.jpg" alt="" />
              </div>
            </div>
          </article>
        </section>
      </main>
    </div>
  );
};

export default Home;
