import { useContext, useRef } from "react";
import FabricItem from "../../components/Fabric/FabricItem/FabricItem";
import Hero from "../../components/Hero/Hero";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import "./style.css";
import { FabricTexts, NavTexts } from "../../texts/texts";
import { GlobalContext } from "../../components/Layout/Layout";
import CardLink from "../../components/Home/SellingPoint/CardLink";
import "aos/dist/aos.css";
import Aos from "aos";

const Fabric = () => {
  Aos.init();

  const { language } = useContext(GlobalContext);

  const { current: fabricCollection } = useRef([
    { img: "chedraui-textil-16.jpg", title: "FRENCH TERRY" },
    { img: "chedraui-textil-17.jpg", title: "FLEECE" },
    { img: "chedraui-textil-18.jpg", title: "INTERLOCK" },
    { img: "chedraui-textil-19.jpg", title: "TOWER BABY" },
    { img: "chedraui-textil-20.jpg", title: "PIQUE" },
    { img: "chedraui-textil-21.jpg", title: "JERSEY" },
    { img: "chedraui-textil-22.jpg", title: "RIB 1x1" },
    { img: "chedraui-textil-23.jpg", title: "TOWER RIB 2x1" },
  ]);

  const sellingPointsLinks = [
    {
      path: "/production",
      img: "/icons/chedraui-textil-icon-1.svg",
      text: NavTexts[1][language],
    },
    {
      path: "/fabric",
      img: "/icons/chedraui-textil-icon-2.svg",
      text: NavTexts[2][language],
    },
    {
      path: "/print",
      img: "/icons/chedraui-textil-icon-3.svg",
      text: NavTexts[3][language],
    },
    {
      path: "/clothing",
      img: "/icons/chedraui-textil-icon-4.svg",
      text: NavTexts[4][language],
    },
    {
      path: "/eco",
      img: "/icons/chedraui-textil-icon-5.svg",
      text: NavTexts[5][language],
    },
  ];

  return (
    <div className="screen fabric">
      <Hero
        img="/chedraui-textil-15.jpg"
        title={FabricTexts[0][language]}
        subtitle={FabricTexts[1][language]}
      />
      <main>
        <div className="background-circle"></div>
        <ul className="selling-points-list">
          {sellingPointsLinks.map((sellingPoint, index) => (
            <CardLink
              key={index}
              path={sellingPoint.path}
              img={sellingPoint.img}
              text={sellingPoint.text}
            />
          ))}
        </ul>
        <section className="fabric-collection">
          <h3 className="section-title" data-aos="fade-right">
            {FabricTexts[2][language][0]}{" "}
            <span className="outline-white">
              {FabricTexts[2][language][1]}{" "}
            </span>
          </h3>
          <article className="fabric-collection-items">
            <div className="row one">
              {fabricCollection.map((item, index) => {
                return (
                  index <= 3 && (
                    <FabricItem key={index} img={item.img} title={item.title} />
                  )
                );
              })}
            </div>
            <div className="row two">
              {fabricCollection.map((item, index) => {
                return (
                  index >= 4 && (
                    <FabricItem key={index} img={item.img} title={item.title} />
                  )
                );
              })}
            </div>
          </article>
          <article id="roller-print" className="roller-print">
            <h3 className="section-title" data-aos="fade-right">
              {FabricTexts[3][language][0]}
              <span className="outline-white">
                {FabricTexts[3][language][1]}
              </span>
            </h3>
            <div className="roller-grid">
              <Gallery>
                <Item
                  className="picture"
                  original="chedraui-textil-24.jpg"
                  thumbnail="chedraui-textil-24.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-24.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-25.jpg"
                  thumbnail="chedraui-textil-25.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-25.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-26.jpg"
                  thumbnail="chedraui-textil-26.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-26.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-27.jpg"
                  thumbnail="chedraui-textil-27.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-27.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-28.jpg"
                  thumbnail="chedraui-textil-28.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-28.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-29.jpg"
                  thumbnail="chedraui-textil-29.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-29.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-30.jpg"
                  thumbnail="chedraui-textil-30.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-30.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-31.jpg"
                  thumbnail="chedraui-textil-31.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-31.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-32.jpg"
                  thumbnail="chedraui-textil-32.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-32.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-33.jpg"
                  thumbnail="chedraui-textil-33.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-33.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-34.jpg"
                  thumbnail="chedraui-textil-34.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-34.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-35.jpg"
                  thumbnail="chedraui-textil-35.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-35.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-36.jpg"
                  thumbnail="chedraui-textil-36.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-36.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-37.jpg"
                  thumbnail="chedraui-textil-37.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-37.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-38.jpg"
                  thumbnail="chedraui-textil-38.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-38.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-39.jpg"
                  thumbnail="chedraui-textil-39.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-39.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-40.jpg"
                  thumbnail="chedraui-textil-40.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-40.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
                <Item
                  className="picture"
                  original="chedraui-textil-41.jpg"
                  thumbnail="chedraui-textil-41.jpg"
                  width="1200"
                  height="1200"
                  alt=""
                >
                  {({ ref, open }) => (
                    <img
                      data-aos="fade-up"
                      src="chedraui-textil-41.jpg"
                      ref={ref}
                      onClick={open}
                      alt=""
                    />
                  )}
                </Item>
              </Gallery>
            </div>
          </article>
        </section>
      </main>
    </div>
  );
};

export default Fabric;
