import { React } from "react";
import { useContext } from "react";
import { ContactTexts } from "../../texts/texts";
import { GlobalContext } from "../../components/Layout/Layout";
import "./style.css";
import "aos/dist/aos.css";
import Aos from "aos";

const Contact = () => {
  Aos.init();

  const { language } = useContext(GlobalContext);

  return (
    <div className="screen contact">
      <article className="column left">
        <img src="chedraui-textil-123.jpg" alt="" data-aos="zoom-in" />
      </article>
      <article className="column right" data-aos="fade-right">
        <h3 className="section">{ContactTexts[1][language]}</h3>
        <div className="contact-info">
          <div className="contact-item">
            <h4>{ContactTexts[2][language]}</h4>
            <div className="content">
              <div className="contact-content-item">
                <h4>{ContactTexts[3][language]}</h4>
                <a href="mailto:chedrauio@gmail.com">chedrauio@gmail.com</a>
                <a href="mailto:oscartufic@hotmail.com">
                  oscartufic@hotmail.com
                </a>
              </div>
              <div className="contact-content-item">
                <h4>{ContactTexts[4][language]}</h4>
                <a href="mailto:design4@chedrauitextil.com.mx">
                  design4@chedrauitextil.com.mx
                </a>
                <a href="design5@chedrauitextil.com.mx">
                  design5@chedrauitextil.com.mx
                </a>
              </div>
              <div className="contact-content-item">
                <h4>{ContactTexts[14][language]}</h4>
                <a href="mailto:recepcion@chedrauitextil.com.mx">
                  recepcion@chedrauitextil.com.mx
                </a>
              </div>
              <div className="contact-content-item">
                <h4>{ContactTexts[5][language]}</h4>
                <a href="mailto:administracion@chedrauitextil.com.mx">
                  administracion@chedrauitextil.com.mx
                </a>
              </div>
              <hr />
            </div>
          </div>
          <div className="contact-item">
            <h4>{ContactTexts[6][language]}</h4>
            <div className="multi-phone">
              <img src="/icons/phone.svg" alt="" />
              <div className="links">
                <a href="tel:522227853293">+52 (222) 785-32-93</a>
                <a href="tel:522222486373">+52 (222) 248-63-73</a>
                <a href="tel:522222483868">+52 (222) 248-38-68</a>
                <a href="tel:522227853293">+52 (222) 785 32 93</a>
              </div>
            </div>
            <hr />
          </div>
          <div className="contact-item">
            <h4>{ContactTexts[7][language]}</h4>
            <a href="https://www.google.com/maps?ll=19.071187,-98.24562&z=14&t=m&hl=en&gl=MX&mapclient=embed&cid=3118624665944644296">
              <img src="icons/location.svg" alt="" />
              <span>
                Km 126.5 carretera Federal México-Puebla S/N Col. Ignacio Romero
                Vargas C.P 72120 Puebla, Pue. MÉXICO.
              </span>
            </a>
            <hr />
          </div>
        </div>
        <div className="contact-form-wrapper">
          <h4>{ContactTexts[8][language]}</h4>
          <form name="formulario" method="post">
            <div className="inputs-wrapper">
              <input type="hidden" name="form-name" value="formulario" />
              <input
                placeholder={ContactTexts[9][language]}
                type="text"
                name="name"
              />
              <input
                placeholder={ContactTexts[10][language]}
                type="email"
                name="email"
                required
              />
              <input
                placeholder={ContactTexts[11][language]}
                type="phone"
                name="phone"
              />
            </div>
            <div className="textarea-wrapper">
              <textarea
                placeholder={ContactTexts[12][language]}
                name="message"
              ></textarea>
            </div>
            <button type="submit">{ContactTexts[13][language]}</button>
          </form>
        </div>
      </article>
    </div>
  );
};

export default Contact;
