import React, { useContext } from "react";
import Hero from "../../components/Hero/Hero";
import OptionSlider from "../../components/AboutUs/OptionSlider/OptionSlider";
import "./style.css";
import PictureTextBlock from "../../components/AboutUs/OptionSlider/PictureTextBlock/PictureTextBlock";
import { AboutTexts, NavTexts } from "../../texts/texts";
import { GlobalContext } from "../../components/Layout/Layout";
import CardLink from "../../components/Home/SellingPoint/CardLink";
import "aos/dist/aos.css";
import Aos from "aos";

const About = () => {
  Aos.init();

  const { language } = useContext(GlobalContext);

  const sellingPointsLinks = [
    {
      path: "/production",
      img: "/icons/chedraui-textil-icon-1.svg",
      text: NavTexts[1][language],
    },
    {
      path: "/fabric",
      img: "/icons/chedraui-textil-icon-2.svg",
      text: NavTexts[2][language],
    },
    {
      path: "/print",
      img: "/icons/chedraui-textil-icon-3.svg",
      text: NavTexts[3][language],
    },
    {
      path: "/clothing",
      img: "/icons/chedraui-textil-icon-4.svg",
      text: NavTexts[4][language],
    },
    {
      path: "/eco",
      img: "/icons/chedraui-textil-icon-5.svg",
      text: NavTexts[5][language],
    },
  ];

  return (
    <div className="screen about-us">
      <Hero
        img="/chedraui-textil-5-1.jpg"
        title={AboutTexts[8][language]}
        subtitle={AboutTexts[9][language]}
      />
      <main>
        <ul className="selling-points-list">
          {sellingPointsLinks.map((sellingPoint, index) => (
            <CardLink
              key={index}
              path={sellingPoint.path}
              img={sellingPoint.img}
              text={sellingPoint.text}
            />
          ))}
        </ul>
        <section className="what-we-do" data-aos="fade-up">
          <OptionSlider />
          <article className="presentation" data-aos="zoom-in">
            <p>
              {AboutTexts[0][language]}
              <br />
              <br />
              {AboutTexts[1][language]}
            </p>
          </article>
        </section>
        <section className="focus-points">
          <PictureTextBlock
            title={AboutTexts[2][language]}
            copy={AboutTexts[3][language]}
            img="/chedraui-textil-8.jpg"
          />
          <PictureTextBlock
            order="right"
            title={AboutTexts[4][language]}
            copy={AboutTexts[5][language]}
            img="/chedraui-textil-9.jpg"
          />
          <PictureTextBlock
            title={AboutTexts[6][language]}
            copy={AboutTexts[7][language]}
            img="/chedraui-textil-10.jpg"
          />
        </section>
      </main>
    </div>
  );
};

export default About;
