import "./style.css";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const PictureGrid = ({ title, collection }) => {
  return (
    <div className="component-picture-grid">
      <h3 data-aos="fade-right">{title}</h3>
      <div className="pictures">
        <Gallery>
          {collection.map((item, index) => {
            return (
              <Item
                key={index + 1}
                className="picture"
                original={item}
                thumbnail={item}
                width="1400"
                height="1050"
                alt=""
              >
                {({ ref, open }) => (
                  <img
                    data-aos="fade-up"
                    src={item}
                    ref={ref}
                    onClick={open}
                    alt=""
                  />
                )}
              </Item>
            );
          })}
        </Gallery>
      </div>
    </div>
  );
};

export default PictureGrid;
