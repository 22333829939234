import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import About from "./pages/AboutUs/AboutUs";
import Clothing from "./pages/Clothing/Clothing";
import Contact from "./pages/Contact/Contact";
import Eco from "./pages/Eco/Eco";
import Error404 from "./pages/Error404/Error404";
import Fabric from "./pages/Fabric/Fabric";
import Home from "./pages/Home/Home";
import Print from "./pages/Print/Print";
import Production from "./pages/Production/Production";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="production" element={<Production />} />
          <Route path="fabric" element={<Fabric />} />
          <Route path="print" element={<Print />} />
          <Route path="clothing" element={<Clothing />} />
          <Route path="eco" element={<Eco />} />
          <Route path="about-us" element={<About />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
