import React from "react";
const Error404 = () => {
  return (
    <div className="screen error-404">
      <h1>Volver al Inicio</h1>
    </div>
  );
};

export default Error404;
